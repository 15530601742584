/* line 4, ../sass/App.sass */
.App {
  text-align: center;
}

/* line 7, ../sass/App.sass */
.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

/* line 16, ../sass/App.sass */
.App-logo {
  width: 50%;
  height: auto;
}

/* line 20, ../sass/App.sass */
.App-bg {
  color: #eee;
  min-height: 100vh;
  position: fixed;
  z-index: -1;
  pointer-events: none;
  font-size: 10px;
  text-align: center;
  transform: rotate(-1deg);
}

/* line 30, ../sass/App.sass */
#mce-EMAIL, #mc-embedded-subscribe {
  padding: 1rem;
  font-size: 1.5rem;
  border: 2px solid black;
  -moz-border-radius: 1rem;
  -webkit-border-radius: 1rem;
  border-radius: 1rem;
}

/* line 36, ../sass/App.sass */
#mc-embedded-subscribe {
  background: #FFBC00;
  margin: 1rem;
  -moz-transition: all 0.25s;
  -o-transition: all 0.25s;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}
/* line 40, ../sass/App.sass */
#mc-embedded-subscribe:hover {
  background: black;
  color: white;
}
